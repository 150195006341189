import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import {
  components,
  assets,
  colors,
  unstable_components,
} from '@ElementsCapitalGroup/enium-ui'
import { ReactComponent as DollarIcon } from 'assets/dollar-icon.svg'
import Textarea from 'components/textarea'
import Modal from 'components/modal'
import StatusComponent from 'components/status'
import {
  AUTOPAY_STATUS_MAPPING,
  LOAN_STATUS_ID_MAPPING,
} from 'modules/loan-applications/constants'
import LoanSummary from 'modules/loan-application/status/loan-summary'
import { updateLoanAppStatus } from 'modules/loan-application/actions'
import { formatIntToCurrency } from 'modules/loan-application/utils'
import { ExpandableCard } from 'components/expandable-card'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { AccessWrapper, hasAccess } from 'common/access'
import Loader, { LOADER_SIZE } from 'components/loader'
import { CLAIMS } from 'common/claims'
import { useStore } from 'store'
import {
  STATES_ORDER,
  STATES_ORDER_COMMERCIAL,
  LOAN_APPLICATION_STATUSES,
  ACH_STATUS,
} from 'modules/loan-application/constants'
import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'
import { formatAddress } from 'common/utils'
import { convertDateToClient, formatDate } from 'common/date'
import { ReactComponent as Edit03Icon } from 'assets/edit03-icon.svg'

import LoanProductModal from './loan-product-modal'
import styles from './style.module.scss'
import { styles as statusStyles } from '../style'

const { IconButton, Dropdown, Dialog, Tooltip } = components
const { Unstable_Typography: Typography } = unstable_components
const {
  FileSearch02Icon,
  EyeIcon,
  ToolIcon,
  ChevronDownIcon,
  AlertCircleIcon,
} = assets

const StatusCard = ({
  isBorrower,
  isOwners,
  className,
  applicantsData,
  loanData,
  statuses,
  reasons,
  canChangeStatus,
  currentState,
  isCommercialApp,
  achStatus,
  onAchOptInClick,
  setIsEcgModalOpen,
}) => {
  const { t: translate } = useTranslation()
  const { dispatch, state } = useStore()
  const { userData } = state.session
  const [reason, setReason] = useState('')
  const [note, setNote] = useState('')
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false)
  const [isReasonModalOpen, setIsReasonModalOpen] = useState(false)
  const [statusToChange, setStatusToChange] = useState(null)
  const [statusLoading, setStatusLoading] = useState(false)
  const [shouldDisplayDropdown, setShouldDisplayDropdown] = useState(false)
  const [isLoanProductModalOpen, setIsLoanProductModalOpen] = useState(false)
  const [showAANModal, setShowAANModal] = useState(false)
  const statesOrder = isCommercialApp ? STATES_ORDER_COMMERCIAL : STATES_ORDER
  const loanAppStatus = loanData.loanStatus?.id || 0
  const loanAppReason = loanData.loanApplicationStatusReason
  const loanAppNote = loanData.loanApplicationStatusReasonNote
  const isEdit = !!loanData.loanApplicationId
  const isAdverseActionNoticesDelivered =
    !!loanData.loanApplicationIconData.isAdverseActionNoticesDelivered
  const canSeeUWHub = useMemo(
    () =>
      hasAccess(userData, CLAIMS.CAN_VIEW_UW_HUB) &&
      !isCommercialApp &&
      statesOrder[currentState] >= statesOrder.NTPSubmitted,
    [userData, statesOrder, isCommercialApp]
  )

  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  const canUpdateOnlyUWFields = useMemo(
    () =>
      isEdit &&
      hasAccess(userData, CLAIMS.CAN_UPDATE_ONLY_UW_FIELDS) &&
      !hasAccess(userData, CLAIMS.CAN_DO_EVERYTHING),
    [userData]
  )

  const areFieldsDisabledAfterIfRequestedState = useMemo(
    () =>
      statesOrder[currentState] >= statesOrder.NTPFundsGranted &&
      statesOrder[currentState] !== statesOrder.NTPDocsReSubmitted &&
      statesOrder[currentState] !== statesOrder.NTPDocsRejected &&
      !hasAccess(userData, CLAIMS.CAN_DO_EVERYTHING),
    [userData]
  )

  const statusesOptions = useMemo(() =>
    statuses.map(
      (s) => ({ ...s, label: translate(s.translationKey) }),
      [statuses]
    )
  )

  const selectedStatus = statuses?.find(
    (s) => s.id === loanAppStatus
  )?.translationKey

  const achStatusText =
    achStatus !== null && achStatus !== undefined
      ? translate(`loanApplication.statusCard.achStatus.status.${achStatus}`)
      : translate(
          `loanApplication.statusCard.achStatus.status.${ACH_STATUS.INCOMPLETE}`
        )

  const areInputsDisabled =
    canUpdateOnlyUWFields || areFieldsDisabledAfterIfRequestedState

  const changeStatus = () => {
    setStatusLoading(true)
    updateLoanAppStatus({
      dispatch,
      loanApplicationId: loanData.loanApplicationId,
      loanApplicationStatusId: statusToChange,
      loanApplicationStatusReasonNote: note,
      loanApplicationStatusReasonId: reason?.id,
    }).finally(() => {
      setStatusLoading(false)
      setReason('')
      setNote('')
      setStatusToChange(null)
    })
  }

  useEffect(() => {
    if (reasons?.find((item) => item.statusId === statusToChange)) {
      setShouldDisplayDropdown(true)
    } else {
      setShouldDisplayDropdown(false)
    }
  }, [reasons, statusToChange])

  const _renderStatusDropdown = () => (
    <div>
      {canChangeStatus ? (
        <Dropdown
          options={statusesOptions}
          value={{
            label: translate(selectedStatus),
            id: loanAppStatus,
          }}
          customTrigger={
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <StatusComponent
                status={LOAN_STATUS_ID_MAPPING[loanData.loanStatus?.id]}
                label={translate(
                  `loanApplication.statusCard.loanApplicationStatus.${loanData.loanStatus?.id}`
                )}
              />
              <ChevronDownIcon sx={{ width: '13px', height: '13px' }} />
            </div>
          }
          onChange={(e) => {
            const value = e.target.value
            if (value !== loanAppStatus) {
              if (
                value === LOAN_APPLICATION_STATUSES.Active &&
                loanAppStatus === LOAN_APPLICATION_STATUSES.Declined &&
                isAdverseActionNoticesDelivered
              ) {
                setShowAANModal(true)
              } else {
                setStatusToChange(value.id)
              }
            }
          }}
        />
      ) : (
        <div>
          &nbsp;
          {translate(
            `loanApplication.statusCard.loanApplicationStatus.${loanData.loanStatus?.id}`
          )}
        </div>
      )}
    </div>
  )

  const _renderGeneralInfoCard = () => {
    const collapsibleItems = [
      <div key={1}>
        <span className={styles.label}>
          {translate('loanApplication.statusCard.summaryModal.salesRepName')}
        </span>
        {loanData.salesRepresentativeName}
      </div>,

      <div key={2} className={styles.labelWrapper}>
        <span className={styles.label}>
          {translate('loanApplication.statusCard.summaryModal.loanProduct')}
        </span>
        <div className={styles.loanProductValueWrapper}>
          {loanData.loanName}
          {loanData.loanName !== 'N/A' && (
            <IconButton
              size="small"
              onClick={() => setIsLoanProductModalOpen(true)}
            >
              <AlertCircleIcon
                fontSize="small"
                sx={{
                  transform: 'rotate(180deg)',
                }}
              />
            </IconButton>
          )}
        </div>
      </div>,

      <div key={3}>
        <span className={styles.label}>
          {translate(
            'loanApplication.statusCard.summaryModal.applicationNumber'
          )}
        </span>
        {loanData.loanApplicationNumber}
      </div>,

      <div key={4}>
        <span className={styles.label}>
          {translate('loanApplication.statusCard.summaryModal.loanAmount')}
        </span>
        {formatIntToCurrency(loanData.loanAmount)}
      </div>,

      <div key={5}>
        <span className={styles.label}>
          {translate(
            'loanApplication.statusCard.summaryModal.salesOrganization'
          )}
        </span>
        {loanData.salesOrganizationName}
      </div>,

      <div key={6}>
        <span className={styles.label}>
          {translate('loanApplication.statusCard.summaryModal.autopay')}
        </span>

        {statesOrder[currentState] < statesOrder.LoanSelected ? (
          <Tooltip
            sx={{ display: !onAchOptInClick ? 'none' : 'inherit' }}
            title={translate(
              'loanApplication.statusCard.achStatus.noLoanSelectedMessage'
            )}
          >
            <span
              data-tip={true}
              data-for="ach-opt-in"
              style={{ cursor: 'pointer' }}
              onClick={onAchOptInClick}
            >
              <StatusComponent
                status={AUTOPAY_STATUS_MAPPING[achStatusText]}
                label={achStatusText}
              />
            </span>
          </Tooltip>
        ) : (
          <span
            data-tip={true}
            data-for="ach-opt-in"
            style={{ cursor: 'pointer' }}
            onClick={onAchOptInClick}
          >
            <StatusComponent
              status={AUTOPAY_STATUS_MAPPING[achStatusText]}
              label={achStatusText}
            />
          </span>
        )}
      </div>,

      <div key={7} style={{ display: 'flex', alignItems: 'center' }}>
        <span className={styles.label}>
          {translate('loanApplication.statusCard.summaryModal.status')}
        </span>

        {canChangeStatus ? (
          _renderStatusDropdown()
        ) : (
          <>
            <StatusComponent
              status={LOAN_STATUS_ID_MAPPING[loanData.loanStatus?.id]}
              label={translate(
                `loanApplication.statusCard.loanApplicationStatus.${loanData.loanStatus?.id}`
              )}
            />
          </>
        )}

        {loanAppReason && (
          <IconButton onClick={() => setIsReasonModalOpen(true)}>
            <EyeIcon fontSize="small" />
          </IconButton>
        )}
      </div>,
      <div key={77}>
        <span className={styles.label}>
          {translate('loanApplication.statusCard.dateCreated')}
        </span>
        {formatDate(
          convertDateToClient(loanData.dateCreated),
          'MMMM Do, YYYY - hh:mm A'
        )}
      </div>,
    ]

    return (
      <ExpandableCard
        items={[]}
        collapsibleItems={collapsibleItems}
        isCollapsable={true}
        title={
          <div>
            <div style={statusStyles.applicantName}>
              {applicantsData[0]?.firstName} {applicantsData[0]?.lastName}
              {applicantsData[1]
                ? ` & ${applicantsData[1].firstName} ${applicantsData[1].lastName}`
                : null}
            </div>
            <div style={statusStyles.applicantAddress}>
              {formatAddress(loanData.serviceAddress)}
            </div>
          </div>
        }
        expandableListStyle={
          isMobileView
            ? {}
            : {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                '& .MuiListItem-root': {
                  padding: '0',
                },
                '& .MuiListItem-root > div': {
                  padding: '12px 24px',
                },
                '& .MuiListItem-root:nth-child(2n+2)': {
                  borderLeft: `1px solid ${colors.grey[200]}`,
                },
              }
        }
        actionButtonsClassName={styles.cardButtons}
        actionButtons={
          <div className={styles.cardsActions}>
            <div
              className={styles.actionItem}
              onClick={() => setIsSummaryModalOpen(!isSummaryModalOpen)}
            >
              <IconButton size="medium">
                <Edit03Icon />
              </IconButton>
              <Typography> {translate('buttons.edit')}</Typography>
            </div>

            {canSeeUWHub && (
              <div
                className={styles.actionItem}
                onClick={() =>
                  window.open(
                    `/loan-application/${loanData.loanApplicationId}/uw-hub`,
                    '_blank'
                  )
                }
              >
                <IconButton size="medium">
                  <FileSearch02Icon
                    sx={{ stroke: colors.blue[700], width: '20px' }}
                  />
                </IconButton>

                <Typography>UW Hub</Typography>
              </div>
            )}
            <AccessWrapper claims={CLAIMS.CAN_VIEW_ECG_TOOLBOX}>
              <div
                className={styles.actionItem}
                onClick={() => setIsEcgModalOpen(true)}
              >
                <IconButton size="medium">
                  <ToolIcon sx={{ stroke: colors.blue[700], width: '20px' }} />
                </IconButton>
                <Typography>
                  {translate('loanApplication.ecgToolbox.title.toolbox')}
                </Typography>
              </div>
            </AccessWrapper>
          </div>
        }
      />
    )
  }

  return (
    <div className="card-status">
      <div className={styles.cardsWrapper}>{_renderGeneralInfoCard()}</div>

      <div className={cx('status-card', className)}>
        {!isBorrower && !isOwners && (
          <div className="status-card__amount-wrapper">
            <div className="status-card__amount">
              <div className="status-card__dollar">
                <DollarIcon />
              </div>
              <div className="status-card__amount-text">
                {formatIntToCurrency(loanData.loanAmount).replace('$', '')}
              </div>
            </div>
          </div>
        )}

        {/* Summary Modal */}
        <LoanSummary
          borrowerDetails={applicantsData?.[0]}
          coBorrowerDetails={applicantsData?.[1]}
          serviceAddress={loanData?.serviceAddress}
          userData={userData}
          loanData={loanData}
          dispatch={dispatch}
          setIsSummaryModalOpen={setIsSummaryModalOpen}
          areInputsDisabled={areInputsDisabled}
          isCommercialApp={isCommercialApp}
          isSummaryModalOpen={isSummaryModalOpen}
          achStatus={achStatus}
        />

        {/* Change Status Modal */}
        <Dialog
          open={statusToChange !== null}
          onClose={() => {
            setStatusToChange(null)
            setNote('')
            setReason('')
          }}
          actions={
            <>
              <Button
                onClick={() => {
                  setStatusToChange(null)
                  setNote('')
                  setReason('')
                }}
                color={BUTTON_COLORS.INHERIT}
                variant={BUTTON_VARIANTS.OUTLINED}
              >
                {translate('buttons.cancel')}
              </Button>
              <Button
                disabled={
                  shouldDisplayDropdown
                    ? reason === '' || !note.trim()
                    : !note.trim()
                }
                onClick={changeStatus}
              >
                {translate('buttons.save')}
              </Button>
            </>
          }
          onConfirm={changeStatus}
          title="Change Loan Application Status"
        >
          <div>
            {statusLoading && (
              <Modal isOpen={true} containerClass="global-loader">
                <Loader size={LOADER_SIZE.XLARGE} />
              </Modal>
            )}

            {shouldDisplayDropdown && (
              <Dropdown
                disabled={statusLoading}
                options={reasons
                  ?.filter((item) => item.statusId === statusToChange)
                  .map((item) => {
                    return {
                      value: item.friendlyName,
                      id: item.id,
                      translationKey: item
                        ? `loanApplication.statusCard.loanApplicationStatusReason.${item.id}`
                        : null,
                      label: item
                        ? translate(
                            `loanApplication.statusCard.loanApplicationStatusReason.${item.id}`
                          )
                        : null,
                    }
                  })}
                label={translate('loanApplication.step2.reason')}
                value={{
                  id: reason.id,
                  label: translate(reason.translationKey),
                }}
                onChange={(e) => {
                  const value = e.target.value
                  setReason(value)
                }}
              />
            )}
            <p>
              {translate(
                'loanApplication.statusCard.loanApplicationStatusReasonNote'
              )}
            </p>
            <Textarea
              value={note}
              onChange={setNote}
              disabled={statusLoading}
            />
          </div>
        </Dialog>

        {/* View Reason Modal */}
        <Dialog
          open={isReasonModalOpen}
          onClose={() => setIsReasonModalOpen(false)}
          title="Reason"
        >
          <span style={{ fontWeight: 'bold' }}>
            {loanAppReason
              ? translate(
                  `loanApplication.statusCard.loanApplicationStatusReason.${loanAppReason.id}`
                )
              : '-'}
          </span>
          <p style={{ marginTop: '30px' }}>
            {translate(
              'loanApplication.statusCard.loanApplicationStatusReasonNote'
            )}
            :
          </p>
          <Textarea value={loanAppNote} disabled={true} onChange={() => {}} />
        </Dialog>

        <Modal
          isOpen={showAANModal}
          onClose={() => setShowAANModal(false)}
          onConfirm={() => {
            setShowAANModal(false)
            setStatusToChange(LOAN_APPLICATION_STATUSES.Active)
          }}
          confirmButton={translate(`buttons.confirm`)}
          cancelButton={translate('buttons.cancel')}
        >
          {translate('loanApplication.step3.areYouSure')}
        </Modal>
        {isLoanProductModalOpen && (
          <LoanProductModal
            loanData={loanData}
            setIsLoanProductModalOpen={setIsLoanProductModalOpen}
          />
        )}
      </div>
    </div>
  )
}

StatusCard.propTypes = {
  isOwners: PropTypes.bool,
  isBorrower: PropTypes.bool,
  className: PropTypes.string,
  loanData: PropTypes.object.isRequired,
  applicantsData: PropTypes.array,
  statuses: PropTypes.array,
  canChangeStatus: PropTypes.bool,
  reasons: PropTypes.array,
  currentState: PropTypes.string.isRequired,
  isCommercialApp: PropTypes.bool,
  achStatus: PropTypes.number,
  onAchOptInClick: PropTypes.func,
  setIsEcgModalOpen: PropTypes.func.isRequired,
}

export default StatusCard
