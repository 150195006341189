import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams, useNavigate } from 'react-router-dom'
import { AccessWrapper } from 'common/access'
import { CLAIMS } from 'common/claims'
import { compareIgnoreCase } from 'common/utils'
import Loader, { LOADER_SIZE } from 'components/loader'
import AdminPageWrapper from 'modules/admin'
import OrganizationBreadcrumbs from 'modules/organizations/organization-details/breadcrumbs'
import { components } from '@ElementsCapitalGroup/enium-ui'
import {
  getOrganizationsTree,
  getRoles,
  getAllOrganizations,
} from 'modules/organizations/actions'
import { addUser } from 'modules/users/actions'
import { useStore } from 'store'
import UserSettings from 'modules/users/users-settings'
import { ReactComponent as UsersSvg } from 'assets/users.svg'

const CreateUserPage = ({ isUserModule }) => {
  const { Breadcrumbs } = components

  const navigate = useNavigate()
  const { state, dispatch } = useStore()
  const { id: orgId } = useParams()
  const [loading, setLoading] = useState(false)
  const { userRoles, organizationTree, allOrganizations } = state.orgManagement

  /** On mount, fetch user data & list of roles */
  useEffect(() => {
    if (!userRoles.length) {
      getRoles(dispatch)
    }
    // For breadcrumbs
    if (!organizationTree.length) {
      getOrganizationsTree(dispatch)
    }
    if (!allOrganizations.length) {
      getAllOrganizations(dispatch)
    }
  }, [orgId])
  const organizationOptions = useMemo(
    () => allOrganizations.map((org) => ({ id: org.guid, label: org.name })),
    [allOrganizations]
  )
  const roleOptions = useMemo(
    () =>
      userRoles
        .map((role) => ({ id: role.roleId, label: role.name }))
        .sort((a, b) => compareIgnoreCase(a.label, b.label)),
    [userRoles]
  )

  const saveUser = (user) => {
    setLoading(true)
    return addUser(dispatch, user)
      .then((res) => {
        if (res?.userGuid) {
          if (isUserModule) {
            navigate(`/admin/users/`)
          } else {
            navigate(`/admin/organization/${orgId}`)
          }
        }
      })
      .finally(() => setLoading(false))
  }

  const isLoading =
    !organizationTree.length || !allOrganizations.length || loading

  const items = [
    { icon: <UsersSvg />, href: '/admin/users' },
    { label: 'Add New User' },
  ]
  return (
    <AccessWrapper claims={[CLAIMS.CAN_ADD_USERS]}>
      <AdminPageWrapper>
        {isLoading ? (
          <Loader
            size={LOADER_SIZE.LARGE}
            center={true}
            style={{ marginTop: '100px' }}
          />
        ) : (
          <>
            {!isUserModule ? (
              <OrganizationBreadcrumbs
                orgId={orgId}
                organizationTree={organizationTree}
                finalItem={{ label: 'Add User' }}
              />
            ) : (
              <Breadcrumbs items={items} onClick={() => {}} sx={{ mb: 2 }} />
            )}
            <UserSettings
              isNew={true}
              onSave={saveUser}
              organizationOptions={organizationOptions}
              roleOptions={roleOptions}
              loading={loading}
            />
          </>
        )}
      </AdminPageWrapper>
    </AccessWrapper>
  )
}

CreateUserPage.propTypes = {
  isUserModule: PropTypes.bool,
}

export default CreateUserPage
