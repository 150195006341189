import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import NTPModule from 'modules/loan-application/ntp'
import { useNavigate } from 'react-router-dom'
import {
  submitNTPForApproval,
  approveNTP,
  requestFunds,
  grantFunds,
  nextStep,
  getAllNTPs,
} from 'modules/loan-application/actions'
import {
  LOAN_APPLICATION_STATES_IDS,
  STATES_ORDER,
  STEPS_MAP,
  STEPS_MAP_COMMERCIAL,
} from '../constants'

/**
 * Module used for both Initial and Final NTP
 */
const InitialAndFinalNTP = ({
  loanApplicationId,
  ntps,
  dispatch,
  currentState,
  userData,
  loanAmount,
  initialFundingAmount,
  loanStatusId,
  serviceAddress,
  estimatedCombinedIncomeToBeProven,
}) => {
  const isCommercial = false

  const isInitial = useMemo(() => {
    return (
      STATES_ORDER[currentState] < STATES_ORDER.NTPFundsGranted ||
      STATES_ORDER[currentState] === STATES_ORDER.NTPDocsReSubmitted ||
      STATES_ORDER[currentState] === STATES_ORDER.NTPDocsRejected
    )
  }, [currentState])

  const navigate = useNavigate()
  const getNTPsCallback = useCallback(
    () => getAllNTPs(dispatch, loanApplicationId),
    []
  )
  const submitNTPCallback = useCallback(
    () => submitNTPForApproval(dispatch, isInitial, loanApplicationId),
    [isInitial]
  )
  const approveNTPCallback = useCallback(
    () =>
      approveNTP(dispatch, isInitial, isCommercial, loanApplicationId).then(
        (result) => {
          if (isCommercial) {
            if (
              result &&
              result.loanApplicationState.id ===
                LOAN_APPLICATION_STATES_IDS.COMMERCIAL_STIPULATIONS_APPROVED
            ) {
              nextStep(
                dispatch,
                navigate,
                STEPS_MAP_COMMERCIAL.COMMERCIAL_NTP,
                {},
                true
              )
            }
          } else if (
            result &&
            result.loanApplicationState.id ===
              LOAN_APPLICATION_STATES_IDS.IF_GRANTED
          ) {
            nextStep(dispatch, navigate, STEPS_MAP.NTP)
          }
        }
      ),
    [isInitial]
  )
  const requestFundsCallback = useCallback(
    () => requestFunds(dispatch, isInitial, loanApplicationId),
    [isInitial]
  )
  const grantFundsCallback = useCallback(
    (amount) =>
      grantFunds(dispatch, isInitial, loanApplicationId, amount).then(
        (result) => {
          if (result) {
            if (!isInitial) {
              /** After initial funds are Granted, move to the Final NTP step */
              nextStep(dispatch, navigate, STEPS_MAP.NTP)
            }
            return result
          }
        }
      ),
    [isInitial]
  )

  return (
    <NTPModule
      commercialNTP={isCommercial}
      isInitial={isInitial}
      ntps={ntps}
      fetchNTPs={getNTPsCallback}
      submitNTPForApproval={submitNTPCallback}
      approveNTP={approveNTPCallback}
      grantFunds={grantFundsCallback}
      requestFunds={requestFundsCallback}
      currentState={currentState}
      userData={userData}
      loanAmount={loanAmount}
      dispatch={dispatch}
      initialFundingAmount={initialFundingAmount}
      loanApplicationId={loanApplicationId}
      loanStatusId={loanStatusId}
      serviceAddress={serviceAddress}
      estimatedCombinedIncomeToBeProven={estimatedCombinedIncomeToBeProven}
    />
  )
}

InitialAndFinalNTP.propTypes = {
  ntps: PropTypes.array.isRequired,
  loanApplicationId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  currentState: PropTypes.string.isRequired,
  userData: PropTypes.object.isRequired,
  loanAmount: PropTypes.number.isRequired,
  loanStatusId: PropTypes.number.isRequired,
  serviceAddress: PropTypes.object,
  estimatedCombinedIncomeToBeProven: PropTypes.number,
  initialFundingAmount: PropTypes.number,
}

export default InitialAndFinalNTP
