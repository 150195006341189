import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'common/hooks'
import { assets } from '@ElementsCapitalGroup/enium-ui'
import { TABLET_BREAKPOINT } from 'common/constants'
import { STATES_ORDER } from 'modules/loan-application/constants'
import {
  downloadAllAttachments,
  getNTPInspections,
  removeNTPComment,
  submitNTPComment,
} from 'modules/loan-application/actions'
import { styles } from 'modules/loan-application/ntp/style'
import { CLAIMS } from 'common/claims'
import Loader from 'components/loader'
import Button, { BUTTON_VARIANTS } from 'components/button'
import { AccessWrapper, hasAccess } from 'common/access'
import NTPInspectionDocuments from './inspection-documents'
import CommentsSection from '../ntp/comments-section'
import { areNtpAttachmentsApproved } from '../ntp/helpers'

const { Download02Icon, SendIcon } = assets

const NTPInspection = ({
  loanApplicationId,
  inspections,
  dispatch,
  currentState,
  userData,
}) => {
  const [loading, setLoading] = useState(false)
  const { t: translate } = useTranslation()
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const [downloadAllLoading, setDownloadAllLoading] = useState(false)
  const [comments, setComments] = useState([])
  const state = STATES_ORDER[currentState]
  const userId = userData?.associations[0]?.userId
  const isInspectionApproved = state >= STATES_ORDER.NTPInspectionApproved
  const canApprove = areNtpAttachmentsApproved(inspections)
  const buttonDisabled = loading || isInspectionApproved || !canApprove
  const uploadedCount = inspections.filter(
    (el) => el.userNTPs.length > 0
  ).length

  const canCommentOnNTPs = hasAccess(userData, CLAIMS.CAN_COMMENT_NTP)
  const canRemoveNTPComments = hasAccess(
    userData,
    CLAIMS.CAN_REMOVE_NTP_COMMENTS
  )

  /** On mount get data */
  useEffect(() => {
    loadInspectionFiles()
  }, [])

  const loadInspectionFiles = () => {
    setLoading(true)
    getNTPInspections(dispatch, loanApplicationId)
      .then((result) => {
        return result
      })
      .finally(() => setLoading(false))
  }

  /** Download all attachments */
  const downloadAll = (ntpSectionId) => {
    setDownloadAllLoading(true)
    downloadAllAttachments(ntpSectionId).finally(() =>
      setDownloadAllLoading(false)
    )
  }

  const submitComment = (comment) => {
    return submitNTPComment(loanApplicationId, comment).then(() =>
      loadInspectionFiles()
    )
  }
  const removeComment = (commentId) => {
    return removeNTPComment(commentId).then(() => {
      setComments(comments.filter((c) => c.ntpCommentId !== commentId))
    })
  }

  return (
    <div style={styles.ntpWrapper}>
      <div style={isMobileView ? styles.ntpContentMobile : styles.ntpContent}>
        <div>
          <div style={styles.header}>
            <div style={styles.headerContent}>
              <div style={styles.headerSubheaderInitial}>
                {translate('loanApplication.ntpStepsCommon.inspection')}
                <div style={styles.subHeader}>
                  {uploadedCount}/{inspections.length}{' '}
                  {translate(
                    'loanApplication.ntpStepsCommon.documentsUploaded'
                  )}
                </div>
              </div>
              <AccessWrapper claims={CLAIMS.CAN_DOWNLOAD_NTPS}>
                {downloadAllLoading ? (
                  <Loader noLogo size={30} />
                ) : (
                  <Button
                    onClick={() => downloadAll(inspections?.[0]?.ntpSectionId)}
                    startIcon={<Download02Icon />}
                    variant={BUTTON_VARIANTS.OUTLINED}
                    sx={{
                      background: 'white',
                    }}
                  >
                    {translate('global.download')}
                  </Button>
                )}
              </AccessWrapper>
            </div>
          </div>

          {inspections.length ? (
            <>
              <NTPInspectionDocuments
                ntps={inspections}
                loanApplicationId={loanApplicationId}
                loading={loading}
                setLoading={setLoading}
                getNtps={getNTPInspections}
                userData={userData}
                updateUserNtpStatus={() => {}}
                loanApplicationStateId={state}
                isInspectionApproved={isInspectionApproved}
              />

              <AccessWrapper claims={CLAIMS.CAN_APPROVE_NTPS}>
                <Button
                  fullWidth={true}
                  disabled={buttonDisabled}
                  loading={loading}
                  startIcon={<SendIcon />}
                  sx={{ marginTop: '16px' }}
                >
                  {translate(
                    'loanApplication.ntpStepsCommon.approveInspection'
                  )}
                </Button>
              </AccessWrapper>
            </>
          ) : null}
        </div>

        <div style={{ marginTop: isMobileView ? '0' : '75px' }}>
          <AccessWrapper claims={CLAIMS.CAN_VIEW_NTP_COMMENTS}>
            <CommentsSection
              comments={comments}
              onSubmit={submitComment}
              canComment={canCommentOnNTPs}
              removeComment={removeComment}
              userId={userId}
              canRemoveNTPComments={canRemoveNTPComments}
            />
          </AccessWrapper>
        </div>
      </div>
    </div>
  )
}

NTPInspection.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  inspections: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  currentState: PropTypes.string.isRequired,
  userData: PropTypes.object.isRequired,
}

export default NTPInspection
