import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import TextField from 'components/input'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import {
  components,
  assets,
  unstable_components,
} from '@ElementsCapitalGroup/enium-ui'

const { Dropdown, Paper, IconButton } = components
const { Unstable_Grid: Grid } = unstable_components
const { TrashIcon } = assets

const AdditionalIncomeDetails = ({
  generalDetails,
  setGeneralDetails,
  errors,
  setErrors,
  coBorrower,
  additionalIncomeTypes,
  isEdit,
  setIsDeleteAdditionalIncomeOpenBorrower,
}) => {
  const { t: translate } = useTranslation()

  const additionalIncomeTypesOptions = useMemo(() => {
    return additionalIncomeTypes.map((v) => ({
      ...v,
      label: translate(v.translationKey),
    }))
  }, [additionalIncomeTypes])

  const selectedIncomeType = useMemo(() => {
    return {
      label: additionalIncomeTypesOptions.find(
        (v) => v.id === generalDetails.additionalIncomeTypeId
      )?.label,
      id: generalDetails.additionalIncomeTypeId,
    }
  }, [generalDetails.additionalIncomeTypeId, additionalIncomeTypesOptions])

  const handleOnChange = (name, e) => {
    const value = typeof e === 'object' ? e.target.value.id : e
    setGeneralDetails({ ...generalDetails, [name]: value })
    if (coBorrower) {
      setErrors({
        ...errors,
        coBorrower: { ...errors.coBorrower, [name]: null },
      })
    } else {
      setErrors({ ...errors, borrower: { ...errors.borrower, [name]: null } })
    }
  }
  return (
    <Paper className="paper">
      <Grid container gap={24}>
        <Grid item className={cx('applicant-info__form-title', 'scan-icon')}>
          {translate('loanApplication.step1.additionalIncome.title')}
          {isEdit && (
            <IconButton
              color="error"
              aria-label="delete"
              onClick={() => setIsDeleteAdditionalIncomeOpenBorrower(true)}
            >
              <TrashIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <Dropdown
            label={translate(
              'loanApplication.step1.additionalIncome.additionalIncomeType'
            )}
            options={additionalIncomeTypesOptions}
            error={Boolean(
              coBorrower
                ? errors.coBorrower.additionalIncomeTypeId
                : errors.borrower.additionalIncomeTypeId
            )}
            helperText={
              coBorrower
                ? errors.coBorrower.additionalIncomeTypeId
                : errors.borrower.additionalIncomeTypeId
            }
            value={selectedIncomeType}
            onChange={(val) => handleOnChange('additionalIncomeTypeId', val)}
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            fullWidth
            label={translate(
              'loanApplication.step1.additionalIncome.additionalYearlyIncome'
            )}
            validate={() =>
              coBorrower
                ? errors.coBorrower.additionalIncomeAmount
                : errors.borrower.additionalIncomeAmount
            }
            value={generalDetails.additionalIncomeAmount}
            onChange={(val) => handleOnChange('additionalIncomeAmount', val)}
            prefix="$"
            type="currency"
            decimalScale={0}
            fixedDecimalScale={false}
          />
        </Grid>
      </Grid>
      <div className="loan-form__additional">
        <span className="loan-form__additional-text">
          {translate(
            'loanApplication.step1.additionalIncome.additionalIncomeNotice'
          )}
          :{' '}
        </span>
        {translate(
          'loanApplication.step1.additionalIncome.additionalIncomeNoticeText'
        )}
      </div>
    </Paper>
  )
}

AdditionalIncomeDetails.propTypes = {
  generalDetails: PropTypes.object.isRequired,
  setGeneralDetails: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  coBorrower: PropTypes.bool,
  additionalIncomeTypes: PropTypes.array.isRequired,
  isEdit: PropTypes.bool,
  setIsDeleteAdditionalIncomeOpenBorrower: PropTypes.func.isRequired,
}

export default AdditionalIncomeDetails
