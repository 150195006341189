import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { components } from '@ElementsCapitalGroup/enium-ui'

import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { downloadFileBase64 } from 'common/utils'
import { CLAIMS } from 'common/claims'
import { hasAccess } from 'common/access'
import FileUploadV2 from 'components/file-upload-v2'
import {
  ATTACHMENT_STATUS_IDS,
  LOAN_APPLICATION_STATES_IDS,
} from 'modules/loan-application/constants'

import {
  downloadSingleAttachment,
  uploadNTP,
  deleteUserNTP,
  setUserNTPStatus,
} from '../actions'
import { UploadedStipulation } from '../ntp/uploaded-stipulation'

import { styles } from '../ntp/style'
import '../ntp/style.scss'

const { Paper, Dialog } = components

const NTPInspectionDocuments = ({
  ntps,
  loanApplicationId,
  loading,
  setLoading,
  getNtps,
  userData,
  updateUserNtpStatus,
  loanApplicationStateId,
  isInspectionApproved,
}) => {
  const { t: translate } = useTranslation()
  const [userNTPToDelete, setUserNTPToDelete] = useState(null)
  const {
    dispatch,
    state: {
      flow: { possibleEditStates, possibleViewOnlyStates },
    },
  } = useStore()

  const canSeeDecisioningButtons = hasAccess(
    userData,
    CLAIMS.CAN_UPDATE_USER_NTP_STATUS
  )

  const isEcg = hasAccess(userData, CLAIMS.CAN_DO_EVERYTHING)

  /** On status change */
  const handleStatusChange = (userNTPId, statusId) => {
    setLoading(true)
    setUserNTPStatus(userNTPId, { statusId })
      .then(updateUserNtpStatus)
      .finally(() => setLoading(false))
  }

  /** On Download */
  const handleDownloadAttachment = (attachmentId) => {
    downloadSingleAttachment(dispatch, attachmentId).then(
      (res) => res && downloadFileBase64(res)
    )
  }

  /** On Delete */
  const handleOnClickDelete = () => {
    const { userNTPId } = userNTPToDelete
    setUserNTPToDelete(null)
    setLoading(true)
    deleteUserNTP(dispatch, loanApplicationId, userNTPId)
      .then(getNtps)
      .finally(() => setLoading(false))
  }

  const viewOnly = useMemo(() => {
    const inspectionStates = [LOAN_APPLICATION_STATES_IDS.NTP_INSPECTION]

    return (
      loanApplicationStateId.id ===
        LOAN_APPLICATION_STATES_IDS.APPLICATION_EXPIRED ||
      (possibleViewOnlyStates.some((s) => inspectionStates.includes(s)) &&
        !possibleEditStates.some((s) => inspectionStates.includes(s)))
    )
  }, [possibleEditStates, possibleViewOnlyStates, loanApplicationStateId])

  const ntpList = ntps.map((item, index) => {
    const canUploadClaim = hasAccess(userData, CLAIMS.CAN_UPLOAD_STIPULATIONS)

    return (
      <Paper
        className="paper paper--no-shadow-box"
        sx={styles.documentCard}
        key={item.applicationNTPId}
      >
        <div>
          <FileUploadV2
            key={item.applicationNTPId}
            onChange={(file) => {
              setLoading(true)
              uploadNTP(item.applicationNTPId, file)
                .then(getNtps)
                .finally(() => setLoading(false))
            }}
            shouldDisplayScanButton={false}
            canUpload={canUploadClaim}
            title={
              <>
                {translate(
                  `loanApplication.ntpStepsCommon.stipulations.${item.stipulationDefinition.guid}.name`
                )}
                {item.applicantName && ` - ${item.applicantName}`}
              </>
            }
            uploadFunctionSettings={{
              method: 'PUT',
              url: `${process.env.REACT_APP_HOST_LENDER_V3}/LoanApplication/upload-user-ntp`,
              data: { applicationNTPId: item.applicationNTPId },
              cb: getNtps,
            }}
          />
        </div>
        <div className="ntp__documents-wrapper">
          {item.userNTPs.map((userNTP) => (
            <div key={userNTP.userNTPId}>
              <UploadedStipulation
                ntp={userNTP}
                stipulationIndex={index}
                setUserNTPToDelete={setUserNTPToDelete}
                canDeleteNTPsAfterApproval={false}
                canSeeDecisioningButtons={
                  canSeeDecisioningButtons && (!isInspectionApproved || isEcg)
                }
                ntpApproved={isInspectionApproved}
                onNeedsReview={() => {
                  handleStatusChange(
                    userNTP.userNTPId,
                    ATTACHMENT_STATUS_IDS.NEEDS_REVIEW
                  )
                }}
                onApprove={() => {
                  handleStatusChange(
                    userNTP.userNTPId,
                    ATTACHMENT_STATUS_IDS.APPROVED
                  )
                }}
                onReject={() => {
                  handleStatusChange(
                    userNTP.userNTPId,
                    ATTACHMENT_STATUS_IDS.DECLINED
                  )
                }}
                onDownloadAttachment={handleDownloadAttachment}
                viewOnly={viewOnly}
              />
            </div>
          ))}
        </div>
      </Paper>
    )
  })

  return (
    <div className={cx('ntp__documents', { disabled: loading })}>
      <Dialog
        open={!!userNTPToDelete}
        title={translate(
          'loanApplication.ntpStepsCommon.removeFileConfirmation'
        )}
        onClose={() => setUserNTPToDelete(null)}
        actions={
          <>
            <Button
              onClick={() => setUserNTPToDelete(null)}
              color={BUTTON_COLORS.INHERIT}
              variant={BUTTON_VARIANTS.OUTLINED}
            >
              {translate('buttons.cancel')}
            </Button>
            <Button onClick={handleOnClickDelete}>
              {translate('buttons.yes')}
            </Button>
          </>
        }
      />

      {ntpList}
    </div>
  )
}

NTPInspectionDocuments.propTypes = {
  ntps: PropTypes.array.isRequired,
  commercialNTP: PropTypes.bool,
  loanApplicationId: PropTypes.string.isRequired,
  estimatedCombinedIncomeToBeProven: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  getNtps: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  updateUserNtpStatus: PropTypes.func,
  loanApplicationStateId: PropTypes.number.isRequired,
  isInspectionApproved: PropTypes.bool,
}

export default NTPInspectionDocuments
